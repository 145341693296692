import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Pdf from './cv_joseph.pdf'
import Logo_ from './img/logo/sislogobig.png';
import Android from './img/logo/android.png';
import Apple from './img/logo/apple.png';
import Windows from './img/logo/windows.png';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import SIS from './SISRevolution.exe';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({

    caro:{ 
        
      backgroundImage:"linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)",
      color:'black',
        padding:'50px',
       
    },
    back:{ 
    backgroundImage:"linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%)",
//      backgroundColor:'black',
      color:'black',
        padding:'10px',
        fontSize:14
       
    }
}));


function Example(props){
    const classes=useStyles()
    return (
        
        <div className={classes.back}>
        <div className={classes.caro}>
        <Grid container spacing={8}>
        <Grid item xs={12} sm={5} md={5} >

                      

                        <Grid container spacing={0} style={{ textAlign: 'left', paddingTop: '2px'}}>
                            <div style={{ textAlign: "center" }}>
                                <img src={Logo_} width="40%" /> <br/>
                            
                            <h2>
                               
                            </h2>
                            <p style={{color:"#1760d3"}}>
                                <small>Harnessing Technology To Safeguard The Education System</small>
                               
                                </p>
                               <br/>
                     
                            </div>
       
        </Grid>
        </Grid>
        <Grid item  xs={12} sm={3} md={3} style={{color:"#1760d3"}}>
    <h2 style={{textAlign:'left'}}> Quick Link</h2>
        <br/>
        
        <Grid container spacing={1} style={{textAlign:'center'}}>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
       <Link to="/contact" >
        Contact Us
       </Link>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
         <Link to='/aboutus'>
        About us
       </Link>
         </Grid>
        
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
        <Link to='/register'>
        Start Using SIS Revolution
       </Link>
         </Grid>
        <Grid item xs={12} sm={12} md={12} style={{textAlign:'left', paddingTop:'6px'}}>
       <Link to='/solution'>
        Our Solution
       </Link>
         </Grid>
        </Grid>
    
        </Grid>
        
        
        
        <Grid item  xs={12} sm={4} md={4} style={{color:"#1760d3"}} >
        <h2 style={{textAlign:'left'}}> Contact</h2>
        <br/>
        <Grid container spacing={1} style={{textAlign:'left'}}>
         <Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <WhatsApp/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        <a href="https://wa.me/+233550542370/?text=Hi there, Please send your message"> WhatsApp Chat</a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <Email/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        <a href='mailto:support@sisrevolution.com'>support@sisrevolution.com</a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <Call/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
         <a href='tel:+233 550542370'>+233 550542370 </a>
         </Grid><Grid item xs={2} sm={1} md={1} style={{textAlign:'right', paddingBottom:'0px'}}>
        <LocationOn/>
         </Grid>
        <Grid item xs={9} sm={11} md={11} style={{textAlign:'left', paddingTop:'6px'}}>
        {/* <a>22 Skyvalley, Brampton ON L6P 2N1, Canada <br/>Bomso-Kumasi, Ghana</a> */}
        <a>Bomso Opposite MTN Office, Close to Lizy's Hotel, Kumasi, Ghana</a>

                            </Grid>
                            <br/>
                            <br/>
                            <div className={classes.root} >

                                <a href="https://web.facebook.com/ravinlead.techgh/" target="_blank" style={{margin:10}} >
                                    <Facebook style={{ fontSize: "30px" }} />
                                </a>


                                <a href="https://www.linkedin.com/company/ravinlead-tech-hub/" target="_blank" style={{ margin: 10 }} >
                                    <LinkedIn style={{ fontSize: "30px" }} />
                                </a>

                                <a href=""  style={{ margin: 10 }}>
                                    <Instagram style={{ fontSize: "30px" }} />
                                </a>


                            </div>
        </Grid>
    
        </Grid>
        
        

      </Grid>
      <br/>
      <Divider/>
      <Container style={{color:"#1760d3"}}>
      <br/>
                               <p align="center" style={{color:"#1760d3"}} ><b>Download The Software</b></p>
                               <br/>
    <Grid container spacing={2}>
<Grid item lg={2} sm={0} md={0}></Grid>
<Grid item lg={2} sm={12} md={4} ><a target='blank' href='https://play.google.com/store/apps/details?id=com.sisteachers.app'>
    <p style={{fontWeight:"bolder", marginBottom:"5px", color:"#1760d3"}}>Teachers' App</p>
    <img src={Android} width="90%" />
    </a>
</Grid>
<Grid item lg={2} sm={12} md={4} >
    <a target='blank' href='https://apps.apple.com/app/sis-revolution-for-teachers/id6447756417 '>
<p style={{fontWeight:"bolder", marginBottom:"5px", color:"#1760d3" }}>Teachers' App</p>

    <img src={Apple} width="90%" />
    </a>
</Grid>
<Grid item lg={3} sm={12} md={4} >
<p style={{fontWeight:"bolder", marginBottom:"5px", color:"#1760d3"}}>Administration App</p>

    <a href={SIS} download="sisrevolution.exe"><img src={Windows} width="90%" /></a>
</Grid>
<Grid item lg={3} sm={0} md={0}></Grid>



</Grid>
</Container>
<br/>
<Divider/>

 </div>
     <p style={{textAlign:'center', fontSize:12, marginTop:"-30px" , marginBottom:"10px"}}>
      Copyright 2025 SIS Revolution all rights reserved <br/>
                Powered by <a href="http://ravinlead.sisrevolution.com" target="_blank">RavinLead Tech Hub</a>
</p>
        </div>
        
   
        
        
    

    )
}


export default Example


